/* eslint-disable no-undef */
/* eslint-disable @webdevstudios/js-coding-standards/functionExpressionRequireDocblock */
/**
 * Mobile Filter
 *
 * @since Aug 26, 2020
 * @author Kelsey
 */
jQuery( function( $ ) {

	//mobile version - detect click event on filters tab

	let placeholder_text = $( '.project-filter .placeholder' ),
		placeholder_text_default = 'Select',
		placeholder_text_selected = placeholder_text.text();

	$( '.project-filter li' ).on( 'click', function( event ) {

		//detect which tab filter item was selected
		const selected_filter = $( event.target ).attr( 'data' );

		//check if user has clicked the placeholder item
		if ( $( event.target ).is( placeholder_text ) ) {
			( placeholder_text_default == placeholder_text.text() ) ? placeholder_text.text( placeholder_text_selected ) : placeholder_text.text( placeholder_text_default );
			$( '.project-filter' ).toggleClass( 'is-open' );

			//check if user has clicked a filter already selected
		} else if ( placeholder_text.attr( 'data' ) == selected_filter ) {
			placeholder_text.text( $( event.target ).text() );
			$( '.project-filter' ).removeClass( 'is-open' );

		} else {

			//close the dropdown and change placeholder text/data-type value
			$( '.project-filter' ).removeClass( 'is-open' );
			placeholder_text.text( $( event.target ).text() ).attr( 'data', selected_filter );
			placeholder_text_selected = $( event.target ).text();

			$( '.project-filter .active' ).removeClass( 'active' );
			$( event.target ).addClass( 'active' );
		}

	} );

} );
