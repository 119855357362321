/* eslint-disable no-undef */
/* eslint-disable @webdevstudios/js-coding-standards/functionExpressionRequireDocblock */
/**
 * AJAX Load More
 *
 * @since May 15, 2020
 * @author Kelsey
 */
jQuery( function( $ ) {

	let canBeLoaded = true;
	const bottomOffset = 1500;
	let catValue = $( '.project-gallery' ).attr( 'data-currentcat' );

	$( document ).on( 'click', '.project-filter a', function( e ) {
		canBeLoaded = true;
		wp_ajax.current_page = 1;
		catValue = $( '.project-gallery' ).attr( 'data-currentcat' );
	} );

	$( window ).scroll( function() {

		if ( $( document ).scrollTop() > ( $( document ).height() - bottomOffset ) && true === canBeLoaded ) {

			$.ajax( {
				url: wp_ajax.ajax_url,
				data: {
					action: 'loadmore', // the parameter for admin-ajax.php
					query: wp_ajax.posts, // loop parameters passed by wp_localize_script()
					page: wp_ajax.current_page, // current page
					cat: catValue,
				},
				type: 'post',
				beforeSend() {
					canBeLoaded = false;
				},
				success( posts ) {
					if ( posts ) {

						$( '.js-filter' ).append( posts ); // insert new posts

						canBeLoaded = true;
						wp_ajax.current_page++;

					}
				},
			} );

		}
	} );

	return false;

} );
