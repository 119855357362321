/**
 * File industries.js
 *
 */
// Make sure everything is loaded first.
gsap.registerPlugin( ScrollTrigger );

if ( ( 'complete' === document.readyState || 'loading' !== document.readyState ) && ! document.documentElement.doScroll ) {
	industriesSlider();
} else {
	document.addEventListener( 'DOMContentLoaded', industriesSlider );
}

/**
 *
 * Initialize the carousel.
 * @since January 31, 2020
 * @author Corey Collins
 */
function industriesSlider() {
	const industriesList = document.querySelectorAll( '.industries-list a' );
	let hoverTimer;

	// Loop through each accordion on the page and add a listener for its header.
	industriesList.forEach( ( industry ) => {

		//industry.addEventListener( 'click', toggleIndustry );
		industry.addEventListener( 'mouseenter', toggleIndustry );
		industry.addEventListener( 'mouseleave', toggleIndustryOut );

	} );

	/**
	 * Handle toggling the accordion.
	 *
	 * @param {Object} event The targeted element.
	 *
	 * @since January 31, 2020
	 * @author Shannon MacMillan, Corey Collins
	 */
	function toggleIndustryOut( ) {
		clearTimeout( hoverTimer );
	}

	/**
	 * Handle toggling the animation.
	 *
	 * @param {Object} event The targeted element.
	 *
	 * @since January 31, 2020
	 * @author Shannon MacMillan, Corey Collins
	 */
	function toggleIndustry( ) {
		const selected = document.querySelector( `#${ this.getAttribute( 'data' ) }` );
		const activeImg = document.querySelector( '.industries-images .img-wrapper.active' );
		hoverTimer = setTimeout( function() {
			if ( ! selected.classList.contains( 'active' ) ) {

				selected.classList.remove( 'hidden' );
				selected.classList.add( 'active' );

				activeImg.classList.remove( 'active' );
				activeImg.classList.add( 'hidden' );

				const tl = gsap.timeline();

				// Out Timeline

				tl.fromTo( activeImg,
					{ scale: 1 },
					{
						scale: .9,
						duration: .5,
					} );
				tl.fromTo( activeImg,
					{ x: 0 },
					{
						x: 999,
						duration: .5,
					} );

				// In Timeline

				tl.fromTo( selected,
					{ scale: 1 },
					{
						scale: 1,
						duration: .5,

					}, 1 );
				tl.fromTo( selected,
					{ x: 999 },
					{
						x: 0,
						duration: .5,

					}, .5 );

			}
		}, 500 );

		//event.preventDefault();

	}

}
