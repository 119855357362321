/**
 * File testimonial-slider.js
 *
 */
// Make sure everything is loaded first.
if ( ( 'complete' === document.readyState || 'loading' !== document.readyState ) && ! document.documentElement.doScroll ) {
	testimonialSlider();
} else {
	document.addEventListener( 'DOMContentLoaded', testimonialSlider );
}

/**
 *
 * Initialize the carousel.
 * @since January 31, 2020
 * @author Corey Collins
 */
function testimonialSlider() {

	jQuery( '.testimony-slider' ).slick( {
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: true,
		dots: false,
		focusOnSelect: true,
		waitForAnimate: true,
		slidesToShow: 1,
	} );
	const thisSlide = document.querySelector( '.slick-active' );
	const bgImage = document.querySelector( '.testimonial-slider' );
	if ( bgImage ) {
		bgImage.style.backgroundImage = `url('${ thisSlide.dataset.img }')`;
	}
	jQuery( '.testimony-slider' ).on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {
		const slide = document.querySelectorAll( `[data-slick-index='${ nextSlide }']` );

		bgImage.style.backgroundImage = `url('${ slide[ 0 ].dataset.img }')`;

	} );

}
