import * as bodyScrollLock from 'body-scroll-lock';

/**
 * File: off-canvas.js
 *
 * Help deal with the off-canvas mobile menu.
 */

// Make sure everything is loaded first.
if ( ( 'complete' === document.readyState || 'loading' !== document.readyState ) && ! document.documentElement.doScroll ) {
	wdsOffCanvas();
} else {
	document.addEventListener( 'DOMContentLoaded', wdsOffCanvas );
}

/**
 * Kick off our off canvas functions.
 *
 * @since January 31, 2020
 * @author Corey Collins
 */
function wdsOffCanvas() {

	// eslint-disable-next-line no-undef
	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	// eslint-disable-next-line no-undef
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;

	const targetElement = document.querySelector( '#main' );

	const offCanvasContainer = document.querySelector( '.off-canvas-container' ),
		offCanvasOpen = document.querySelector( '.off-canvas-open' );

	offCanvasOpen.addEventListener( 'click', toggleOffCanvas );
	document.body.addEventListener( 'keydown', closeOnEscape );

	/**
	 * Close everything when we hit the escape key.
	 *
	 * @param {Object} event The event trigger.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 */
	function closeOnEscape( event ) {
		if ( 27 === event.keyCode ) {
			closeOffCanvas();
		}
	}

	/**
	 * Toggle the display of the off-canvas overlay.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 */
	function toggleOffCanvas() {
		if ( 'true' === offCanvasOpen.getAttribute( 'aria-expanded' ) ) {
			closeOffCanvas();
			enableBodyScroll( targetElement );
		} else {
			openOffCanvas();
			disableBodyScroll( targetElement );
		}

	}

	/**
	 * Handle closing the off-canvas overlay.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 */
	function closeOffCanvas() {
		offCanvasContainer.classList.remove( 'is-visible' );
		offCanvasOpen.classList.remove( 'is-visible' );

		offCanvasContainer.setAttribute( 'aria-hidden', true );
		offCanvasOpen.setAttribute( 'aria-expanded', false );
	}

	/**
	 * Handle opening the off-canvas overlay.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 */
	function openOffCanvas() {
		offCanvasContainer.classList.add( 'is-visible' );
		offCanvasOpen.classList.add( 'is-visible' );

		offCanvasContainer.setAttribute( 'aria-hidden', false );
		offCanvasOpen.setAttribute( 'aria-expanded', true );
	}
}
