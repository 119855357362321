/* eslint-disable no-var */
/* eslint-disable require-jsdoc */
/* eslint-disable prefer-const */
/**
 * Accordion block functionality
 *
 * @since January 31, 2020
 * @author Shannon MacMillan, Corey Collins
 */
function wdsAccordion() {

	/**
	 * Handle toggling the accordion.
	 *
	 * @param {Object} event The targeted element.
	 *
	 * @since January 31, 2020
	 * @author Shannon MacMillan, Corey Collins
	 */
	window.gianniAccordion = ( function() {
		return class {

		  transitionendEventName() {
				let i,
			  el = document.createElement( 'div' ),
			  transitions = {
						transition: 'transitionend',
						OTransition: 'otransitionend',
						MozTransition: 'transitionend',
						WebkitTransition: 'webkitTransitionEnd',
			  };

				for ( i in transitions ) {
			  if ( transitions.hasOwnProperty( i ) && el.style[ i ] !== undefined ) {
						return transitions[ i ];
			  }
				}
		  }

		  expand( el ) {
				function resetHeight( ev ) {
			  if ( ev.target != el.content ) {
						return;
					}
			  el.content.removeEventListener( this.transitionendevent, bindEvent );

			  if ( ! el.isOpen ) {
						return;
					}

			  requestAnimationFrame( () => {
						el.content.style.transition = '0';
						el.content.style.height = 'auto';

						requestAnimationFrame( () => {
				  el.content.style.height = null;
				  el.content.style.transition = null;

				  this.setExpandedAria( el );
				  el.wrapper.classList.add( this.expandedClass );
				  this.trySetTabIndex( el.content, 0 );

				  this.fire( 'elementOpened', el );
						} );
			  } );
				}

				var bindEvent = resetHeight.bind( this );
				el.content.addEventListener( this.transitionendevent, bindEvent );

				el.isOpen = true;
				el.wrapper.classList.remove( this.collapsedClass );
				el.content.style.height = el.content.scrollHeight + 'px';
		  }

		  collapse( el ) {

				function endTransition( ev ) {
			  if ( ev.target !== el.content ) {
						return;
					}
			  el.content.removeEventListener( this.transitionendevent, bindEvent );

			  if ( el.isOpen ) {
						return;
					}

			  this.fire( 'elementClosed', el );
			  this.setCollapsedAria( el );
			  el.wrapper.classList.add( this.collapsedClass );
			  this.trySetTabIndex( el.content, -1 );
				}

				var bindEvent = endTransition.bind( this );
				el.content.addEventListener( this.transitionendevent, bindEvent );

				el.isOpen = false;
				el.wrapper.classList.remove( this.expandedClass );

				requestAnimationFrame( () => {
			  el.content.style.transition = '0';
			  el.content.style.height = el.content.scrollHeight + 'px';

			  requestAnimationFrame( () => {
						el.content.style.transition = null;
						el.content.style.height = this.collapsedHeight;
			  } );
				} );
		  }

		  open( el ) {
				el.selected = true;
				this.fire( 'elementSelected', el );
				this.expand( el );
				el.wrapper.classList.add( this.selectedClass );
		  }

		  close( el ) {
				el.selected = false;
				this.fire( 'elementUnselected', el );
				this.collapse( el );
				el.wrapper.classList.remove( this.selectedClass );
		  }

		  toggle( el ) {
				if ( el.selected ) {
			  this.close( el );
				} else {
			  this.open( el );

			  if ( this.oneAtATime ) {
						this.els.filter( ( e ) => e !== el && e.selected ).forEach( ( e ) => {
				  this.close( e );
						} );
			  }
				}
		  }

		  trySetTabIndex( el, index ) {
				const tappableElements = el.querySelectorAll( this.defaultElements );
				if ( tappableElements ) {
			  tappableElements.forEach( ( e ) => {
						e.setAttribute( 'tabindex', index );
			  } );
				}
		  }

		  setExpandedAria( el ) {
				el.trigger.setAttribute( 'aria-expanded', 'true' );
				el.content.setAttribute( 'aria-hidden', 'false' );
		  }

		  setCollapsedAria( el ) {
				el.trigger.setAttribute( 'aria-expanded', 'false' );
				el.content.setAttribute( 'aria-hidden', 'true' );
		  }

		  attachEvents() {
				this.els.forEach( ( el, i ) => {
			  el.trigger.addEventListener( 'click', this.toggle.bind( this, el ) );
				} );
		  }

		  setDefaultData() {
				this.els = [];
				this.events = {
			  elementSelected: [],
			  elementOpened: [],
			  elementUnselected: [],
			  elementClosed: [],
				};
				this.transitionendevent = this.transitionendEventName();
				this.oneAtATime = true;
				this.selectedClass = 'selected';
				this.expandedClass = 'expanded';
				this.collapsedClass = 'collapsed';
				this.trigger = '[data-accordion-element-trigger]';
				this.content = '[data-accordion-element-content]';
				this.collapsedHeight = '0px';
				this.defaultElements = [ 'a', 'button', 'input' ];
				this.openAtLandingIndex = -1;
		  }

		  setCustomData( data ) {
				const keys = Object.keys( data );

				for ( let i = 0; i < keys.length; i++ ) {
			  this[ keys[ i ] ] = data[ keys[ i ] ];
				}
		  }

		  fire( eventName, el ) {
				const callbacks = this.events[ eventName ];
				for ( let i = 0; i < callbacks.length; i++ ) {
			  callbacks[ i ]( el );
				}
		  }

		  on( eventName, cb ) {
				if ( ! this.events[ eventName ] ) {
					return;
				}
				this.events[ eventName ].push( cb );
		  }

		  constructor( data ) {
				this.setDefaultData();
				this.setCustomData( data ); // ES6 => Object.assign(this, data)

				[].forEach.call( document.querySelectorAll( this.elements ), ( el, i ) => {
			  this.els.push( {
						wrapper: el,
						trigger: el.querySelector( this.trigger ),
						content: el.querySelector( this.content ),
			  } );

			  const element = this.els[ i ];

			  if ( this.openAtLandingIndex !== i ) {
						element.content.style.height = this.collapsedHeight;
						element.wrapper.classList.add( this.collapsedClass );
						this.setCollapsedAria( element );

			  } else {
						element.selected = true;
						element.wrapper.classList.add( this.selectedClass );
						element.wrapper.classList.add( this.expandedClass );
						this.setExpandedAria( element );
						this.fire( 'elementSelectedAtLanding', element );
			  }
				} );

				this.attachEvents();
		  }

		};
	  } () );

	  const myAccordion = new gianniAccordion( {
		elements: '.accordion-item',
		trigger: '.accordion-item-header',
		content: '.accordion-item-content',

		// collapsedHeight: '135px' -> maybe you have only one accordion and the content is partial visible
		// selectedClass: 'my-custom-selected-class' -> by default is 'selected'
		// openAtLandingIndex: 2 -> set 3rd element open at landing
	  } );

	// Open the hash link if one exists.
	openHashLink();

}

/**
 * Checks for a hash link in the URL and if one exists and matches an accordion, opens that accordion item.
 *
 * @since January 31, 2020
 * @author Shannon MacMillan, Corey Collins
 *
 * @return {boolean} Early bail of no hash.
 */
function openHashLink() {
	if ( ! window.location.hash ) {
		return false;
	}
	if ( ! document.querySelector( '.accordion-item-toggle' ) ) {
		return false;
	}
	const hashAccordionItem = document.querySelector( window.location.hash ),
		hashAccordionItemHeader = hashAccordionItem.previousElementSibling,
		hashAccordionItemButton = hashAccordionItemHeader.querySelector( '.accordion-item-toggle' );
	// console.log( hashAccordionItem.id );

	 gsap.to( window, { duration: 2, scrollTo: { y: `#${ hashAccordionItem.id }`, offsetY: 300 } } );

	hashAccordionItemButton.click();
}

// Handles ACF + Goots backend integration.
if ( window.acf ) {
	window.acf.addAction( 'render_block_preview/type=wds-accordion', wdsAccordion );
}

// Fire off our function.
wdsAccordion();
