/* eslint-disable object-shorthand */
/* eslint-disable @webdevstudios/js-coding-standards/functionExpressionRequireDocblock */
/**
 * AJAX Filter Functionality
 *
 * @since August 24, 2020
 * @author Kelsey
 */
( function( $ ) {
	$( document ).ready( function() {

		//Filter Ajax Event
		$( document ).on( 'click', '.project-filter .filterable', function( e ) {

			$( '.project-filter a' ).removeClass( 'active' );
			$( this ).addClass( 'active' );

			e.preventDefault();
			const catValue = this.dataset.term;

			$( '.project-gallery' ).attr( 'data-currentcat', catValue );

			// eslint-disable-next-line no-undef
			gsap.to( '.js-filter', { duration: .3, y: 50, opacity: 0, onComplete: function() {

				$.ajax( {
					// eslint-disable-next-line no-undef
					url: wp_ajax.ajax_url,
					data: { action: 'filter',
						cat_value: catValue,
					},
					cache: false,
					type: 'post',
					success: function( result ) {
						$( '.js-filter' ).html( result );
						gsap.to( '.js-filter', { duration: .2, y: 0, opacity: 1 } );
					},
					error: function( result ) {
						console.warn( result );
					},

				} );
			} } );

		} );
	} );
} ( jQuery ) );
