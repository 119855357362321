/* eslint-disable require-jsdoc */
/* eslint-disable object-shorthand */
/* eslint-disable @webdevstudios/js-coding-standards/functionExpressionRequireDocblock */
/**
 * Timeline Functionality
 *
 * @since August 28, 2020
 * @author Kelsey
 */

// eslint-disable-next-line no-undef
gsap.registerPlugin( ScrollTrigger );
// eslint-disable-next-line no-undef

( function( $ ) {

	/**
	 * Control the BG of the left section of timeline.
	 *
	 * @since September 2020
	 * @author Kelsey McAuley
	 *
	 */
	function bgMoverFunction() {

		const bgMover = document.querySelector( '.line-bg-mover' );

		if ( ! bgMover ) {
			return;
		}

		// arrow smooth scroll.
		const arrowBtn = document.querySelector( '.down-arrow' );
		$( arrowBtn ).on( 'click', function( event ) {
			event.preventDefault();
			gsap.to( window, { duration: 2, scrollTo: '#timeline-1' } );
		} );

		bgMover.setAttribute( 'style', 'height: 60vh' );

		$( window ).on( 'scroll', function() {

			const entryHeader = $( '.entry-header' ).height();
			const scrollPosition = window.pageYOffset;

			if ( entryHeader < scrollPosition ) {
				bgMover.classList.add( 'sticky' );

			} else {
				bgMover.classList.remove( 'sticky' );
				bgMover.setAttribute( 'style', 'height: 60vh' );
			}

		} );

		//timeline marker.

		//eslint-disable-next-line no-undef
		gsap.from( '.line-bg-mover', {
			scrollTrigger: {
				trigger: '.line-bg-mover',
				scrub: 1,
				start: 'top bottom',
				end: 'top top',
			},
			scaleY: 0,
			transformOrigin: 'top',
			ease: 'none',
		} );
	}

	//GSAP animations

	const tlSection = document.querySelectorAll( '.tl-section' );

	tlSection.forEach( ( section ) => {

		const headLine = section.querySelector( '.header-wrapper' );
		const content = section.querySelector( '.content' );
		const divider = section.querySelector( '.divider' );
		const innerSection = section.querySelector( '.timeline-inner' );
		const year = section.querySelector( '.year' );
		const overlay = section.querySelector( '.overylay' );

		function scene1() {
			// eslint-disable-next-line no-undef
			const tl = gsap.timeline( {
				scrollTrigger: {
					trigger: section,
					start: 'top 40%', // when the top of the trigger hits the top of the viewport
					end: 'bottom 90%',
					pin: year,
					pinSpacing: false,
					scrub: 0, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
				},
			} );
			return tl;
		}

		function scene2() {
			// eslint-disable-next-line no-undef
			const tl = gsap.timeline( {
				scrollTrigger: {
					trigger: section,
					start: 'top 40%', // when the top of the trigger hits the top of the viewport
					end: 'bottom 90%',
					scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
					pin: innerSection,
					pinSpacing: false,
					snap: 1,
				},
			} );

			// add animations and labels to the timeline
			tl.addLabel( 'start' )
				.addLabel( 'header-wrapper' )
				.from( headLine, { autoAlpha: 0 } )
				.addLabel( 'content' )
				.from( content, { autoAlpha: 0 } )
				.addLabel( 'divider' )
				.from( divider, { y: 100, autoAlpha: 0 }, '-=.5' )
				.addLabel( 'end' );

			return tl;
		}
		function scene3() {
			// eslint-disable-next-line no-undef
			const tl = gsap.timeline( {
				scrollTrigger: {
					trigger: section,
					start: 'top 50%', // when the top of the trigger hits the top of the viewport
					end: 'bottom 80%',
					scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
				},
			} );
			tl.from( overlay, { autoAlpha: 0 } );

			return tl;
		}
		function mobile() {
			// eslint-disable-next-line no-undef
			const tl = gsap.timeline( {
				scrollTrigger: {
					trigger: section,
					start: 'top center', // when the top of the trigger hits the top of the viewport
					end: 'top 20%',
					scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
					pin: innerSection,
					pinSpacing: false,
					snap: {
						snapTo: 'labels', // snap to the closest label in the timeline
						duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
						delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
						ease: 'power1.inOut', // the ease of the snap animation ("power3" by default)
					},
				},
			} );

			// add animations and labels to the timeline
			tl.addLabel( 'start' )
				.addLabel( 'header-wrapper' )
				.from( headLine, { y: 100, autoAlpha: 0 } )
				.addLabel( 'content' )
				.from( content, { y: 100, autoAlpha: 0 } )
				.addLabel( 'divider' )
				.from( divider, { y: 100, autoAlpha: 0 } )
				.addLabel( 'end' );

			return tl;
		}
		// eslint-disable-next-line no-undef
		ScrollTrigger.matchMedia( {

			//desktop
			'(min-width: 600px)': function() {
			// eslint-disable-next-line no-undef
				gsap.timeline()
					.add( scene2() )
					.add( scene3(), '-=.5' )
					.add( scene1(), '-=1.5' ); // overlap slightly

			},

			//mobile

			'(max-width: 600px)': function() {
			// eslint-disable-next-line no-undef
				gsap.timeline()
					.add( mobile() ); // overlap slightly
			},

			//all
		} );
	} );

	bgMoverFunction();

} ( jQuery ) );
