/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable require-jsdoc */
/* eslint-disable prefer-const */
/**
 * Animations using GSAP
 *
 * @since Sept, 2020
 * @author Kelsey McAuley
 */

// eslint-disable-next-line no-undef
gsap.registerPlugin( ScrollTrigger );

( function() {

	/**
	 * Control the BG of the left section of timeline.
	 *
	 * @since September 2020
	 * @author Kelsey McAuley
	 *
	 */
	function homeIcons() {
		const USP = document.querySelector( '.usp' );

		if ( ! USP ) {
			return;
		}

		gsap.set( '.twinkle-lg, .twinkle-md', { transformOrigin: 'center center' } );

		const homeTL = gsap.timeline( {
			scrollTrigger: {
				trigger: '.usp',
				start: 'top 80%',
				stop: 'bottom top',
				scrub: false,
				toggleActions: 'play pause resume reset',
			},
		} );

		// Trust Icon TL

		const trustTL = gsap.timeline();

		trustTL.from( '.trust-icon', {
			duration: 1,
			y: 20,
			yoyo: true,
			repeat: 3,
			ease: 'power.inOut',
		} );

		// People icon TL

		const peopleTL = gsap.timeline();

		peopleTL.from( '.person-side-left', {
			x: 20,
			duration: 1,
			ease: 'power2.in',
		} );
		peopleTL.from( '.person-side-left', {
			autoAlpha: 0,
			duration: .5,
		}, '-=.25' );
		peopleTL.from( '.person-side-right', {
			x: -20,
			duration: 1,
			ease: 'power2.in',
		}, '-=.5' );
		peopleTL.from( '.person-side-right', {
			autoAlpha: 0,
			duration: .5,
		}, '-=.25' );

		// Peace icon TL

		const peaceTL = gsap.timeline();

		peaceTL.from( '.twinkle', {
			scale: 0,
			duration: 2,
			ease: 'elastic.inOut',
			stagger: .3,
		} );

		// peaceTL.from( '.twinkle-lg', {
		// 	rotation: 360,
		// 	duration: .5,
		// 	repeat: 5,
		// 	yoyo: true,
		// 	ease: 'power.inOut',
		// 	stagger: true,
		// } );

		// peaceTL.from( '.twinkle-md', {
		// 	rotation: 360,
		// 	duration: .5,
		// 	repeat: 5,
		// 	yoyo: true,
		// 	ease: 'power.inOut',
		// 	stagger: true,
		// }, '-=2' );

		// peaceTL.fromTo( '.twinkle-sm', {
		// 	scale: .75,
		// 	autoAlpha: 1,
		// },
		// {
		// 	scale: 1,
		// 	autoAlpha: 0,
		// 	yoyo: true,
		// 	repeat: 5,
		// 	stagger: true,
		// }, '-=5' );

		// Build home squence
		homeTL.add( trustTL );
		homeTL.add( peopleTL, '-=2.75' );
		homeTL.add( peaceTL, '-=1.5' );

	}
	homeIcons();

	/**
	 * Page hero animation.
	 *
	 * @since September 2020
	 * @author Kelsey McAuley
	 *
	 */
	function homeHero() {

		const title = document.querySelector( '.hero-title' );
		const heroImage = document.querySelector( '.hero-img' );

		if ( ! title || ! heroImage ) {
			return;
		}

		const hero = gsap.timeline();

		hero.to ( heroImage, {
			opacity: 1,
			duration: 1,
			ease: 'power1.in',
		} );

		hero.from( title, {
			y: 300,
			delay: .5,
			duration: 1.25,
			ease: 'power.in',
		}, '-=1' );

		const bgTL = gsap.timeline( {
			scrollTrigger: {
				trigger: '.home-hero',
				start: 'top top',
				bottom: 'bottom top',
				scrub: .5,
			},
		} );

		bgTL.to( heroImage, {
			opacity: 0,
			yPercent: 30,
			ease: 'none',

		} );

	}
	homeHero();

	/**
	 * Page hero animation.
	 *
	 * @since September 2020
	 * @author Kelsey McAuley
	 *
	 */
	function pageHero() {

		const title = document.querySelector( '.page-title' );
		const heroImage = document.querySelector( '.hero' );

		if ( ! title || ! heroImage ) {
			return;
		}

		const hero = gsap.timeline();

		hero.to( title, {
			opacity: .6,
			duration: .5,
		} );

		hero.from( title, {
			y: 300,
			duration: 1.25,
			ease: 'power.in',
		}, '-=.5' );

		const bgTL = gsap.timeline( {
			scrollTrigger: {
				trigger: '.home-hero',
				start: 'top top',
				bottom: 'bottom top',
				scrub: .5,
			},
		} );

		bgTL.to( heroImage, {
			yPercent: 60,
			ease: 'none',

		} );
		const subHeader = document.querySelector( '#sub-header' );
		const downArrow = document.querySelector( '.down-arrow' );

		if ( subHeader ) {
			hero.from( subHeader, {
				y: 200,
				autoAlpha: 0,
				duration: 1,
				ease: 'power.in',
			}, '-=.5' );
		}
		if ( downArrow ) {
			hero.from( downArrow, {
				y: 200,
				opacity: 0,
				duration: .5,
				ease: 'power1.in',
			}, '-=1' );

		}

	}
	pageHero();

	/**
	 * Page hero animation.
	 *
	 * @since September 2020
	 * @author Kelsey McAuley
	 *
	 */
	function bigText() {
		const text = document.querySelector( '.bg-text' );
		if ( ! text ) {
			return;
		}

		const textTL = gsap.timeline( {
			scrollTrigger: {
				trigger: '.container',
				start: 'top top',
				bottom: 'bottom top',
				scrub: .5,
			},
		} );

		textTL.to( text, {
			yPercent: 60,
			ease: 'none',

		} );

	}

	bigText();

}() );
