/**
 * File: navigation-primary.js
 *
 * Helpers for the primary navigation.
 */
( function() {
	const subMenuParentItem = document.querySelectorAll( '.main-navigation .menu-item-has-children' );

	document.addEventListener( 'DOMContentLoaded', toggleFocusClass );
	document.addEventListener( 'DOMContentLoaded', menuSlider );
	window.addEventListener( 'scroll', stickyMenu );

	//document.addEventListener( 'DOMContentLoaded', menuDropdownBackground );

	/**
	 * Adds the menu sliding indicator.
	 *
	 * @since July 15, 2020
	 * @author Kelsey
	 */
	function menuSlider() {
		const target = document.querySelector( '.nav-target' );
		const links = document.querySelectorAll( '#primary-menu > li > a' );
		const linksWrapper = document.querySelectorAll( '#primary-menu > li' );

		// const dropdown = document.querySelector( '.nav-dropdown' );

		/**
		 * Mouse over event.
		 *
		 * @since July 15, 2020
		 * @author Kelsey
 		 */
		function mouseenterFunc( ) {
			if ( ! this.parentNode.classList.contains( 'active' ) ) {
				for ( let i = 0; i < links.length; i++ ) {
					if ( links[ i ].parentNode.classList.contains( 'active' ) ) {
						links[ i ].parentNode.classList.remove( 'active' );
					}
					links[ i ].style.opacity = '0.75';
				}

				this.parentNode.classList.add( 'active' );
				this.style.opacity = '1';

				const width = this.getBoundingClientRect().width;
				const height = this.getBoundingClientRect().height;
				const left = this.getBoundingClientRect().left + window.pageXOffset;

				target.style.width = `${ width }px`;
				target.style.height = `${ height }px`;
				target.style.left = `${ left }px`;
				target.style.transform = 'none';

				//Sliding Dropdown background.

				// const dropdownActive = document.querySelector( '.active .sub-menu' );

				// const dropdownWidth = dropdownActive.getBoundingClientRect().width;
				// const dropdownHeight = dropdownActive.getBoundingClientRect().height;
				// const dropdownLeft = dropdownActive.getBoundingClientRect().left + window.pageXOffset;
				// const dropdownTop = dropdownActive.getBoundingClientRect().top + window.pageYOffset;

				// dropdown.style.width = `${ dropdownWidth }px`;
				// dropdown.style.height = `${ dropdownHeight }px`;
				// dropdown.style.left = `${ dropdownLeft }px`;
				// dropdown.style.top = `${ dropdownTop }px`;
				// dropdown.style.transform = 'none';
				// dropdown.style.visibility = 'visible';
			}
		}

		/**
		 * Mouse out event.
		 *
		 * @since July 15, 2020
		 * @author Kelsey
 		 */
		function mouseoutFunc( ) {
			const active = document.querySelector( '.main-navigation li.active' );
			if ( active ) {
				target.style.width = '0';
				active.classList.remove( 'active' );
			}
			for ( let i = 0; i < links.length; i++ ) {
				links[ i ].style.opacity = '1';
			}
		}

		for ( let i = 0; i < links.length; i++ ) {
			links[ i ].addEventListener( 'mouseenter', mouseenterFunc );
		}
		for ( let i = 0; i < linksWrapper.length; i++ ) {
			linksWrapper[ i ].addEventListener( 'mouseleave', mouseoutFunc );
		}

		/**
		 * Resize Browser.
		 *
		 * @since July 15, 2020
		 * @author Kelsey
 		 */
		function resizeFunc() {
			const active = document.querySelector( '.main-navigation li.active' );

			if ( active ) {
				const left = active.getBoundingClientRect().left + window.pageXOffset;
				const top = active.getBoundingClientRect().top + window.pageYOffset;

				target.style.left = `${ left }px`;
				target.style.top = `${ top }px`;
			}

		}
		window.addEventListener( 'resize', resizeFunc );
	}

	/**
	 * Adds event listeners for tabbing in and out of parent items.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 */
	function toggleFocusClass() {
		subMenuParentItem.forEach( ( parentItem ) => {
			parentItem.addEventListener( 'focusin', toggleIn );
			parentItem.addEventListener( 'focusout', toggleOut );
		} );
	}

	/**
	 * Handle toggling a parent menu on.
	 *
	 * @param {Object} event The triggered event.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 */
	function toggleIn( event ) {
		const parentMenuItems = getParents( event.target.parentNode, '.menu-item-has-children' );
		parentMenuItems.forEach( ( parentItem ) => {
			parentItem.classList.add( 'focus' );
		} );
	}

	/**
	 * Handle toggling a parent menu off.
	 *
	 * @param {Object} event The triggered event.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 */
	function toggleOut( event ) {
		const parentMenuItems = getParents( event.target.parentNode, '.menu-item-has-children' );
		parentMenuItems.forEach( ( parentItem ) => {
			parentItem.classList.remove( 'focus' );
		} );
	}

	/**
	 * Sticky Menu.
	 *
	 *
	 * @since July 28, 2020
	 * @author Kelsey McAuley
	 */
	function stickyMenu() {
		const mainHeader = document.querySelectorAll( '.site-header ' );

		const scrollPosition = window.pageYOffset;

		if ( 40 < scrollPosition ) {
			mainHeader[ 0 ].classList.add( 'sticky' );

		} else {
			mainHeader[ 0 ].classList.remove( 'sticky' );
		}

	}

	/* eslint-disable func-style */
	/**
	 * Get all of the parents for a matching element and selector.
	 * https://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/#getting-all-matches-up-the-tree
	 *
	 * @param {Object} elem The parent menu item.
	 * @param {string} selector The CSS class of the element.
	 *
	 * @since January 31, 2020
	 * @author Corey Collins
	 *
	 * @return {Array} Parents.
	 */
	const getParents = function( elem, selector ) {

		// Element.matches() polyfill.
		if ( ! Element.prototype.matches ) {
			Element.prototype.matches =
				Element.prototype.matchesSelector ||
				Element.prototype.mozMatchesSelector ||
				Element.prototype.msMatchesSelector ||
				Element.prototype.oMatchesSelector ||
				Element.prototype.webkitMatchesSelector ||
				function( s ) {
					const matches = ( this.document || this.ownerDocument ).querySelectorAll( s );
					let i = matches.length;
					while ( 0 >= --i && matches.item( i ) !== this ) {}
					return -1 > i;
				};
		}

		// Setup parents array.
		const parents = [];

		// Get matching parent elements.
		for ( ; elem && elem !== document; elem = elem.parentNode ) {

			// Add matching parents to array.
			if ( selector ) {
				if ( elem.matches( selector ) ) {
					parents.push( elem );
				}
			} else {
				parents.push( elem );
			}
		}

		return parents;
	};
}() );
